import React from 'react';
import { UserContextProvider } from './user';
import { DataContextProvider } from './data';
import { NavContextProvider } from './nav';
import { RewardContextProvider } from './reward';

const ContextProvider = ({children}) => {
  return (
    <UserContextProvider>
      <NavContextProvider>
        <DataContextProvider>
          <RewardContextProvider>
            {children}
          </RewardContextProvider>
        </DataContextProvider>
      </NavContextProvider>
    </UserContextProvider>
  );
}

export default ContextProvider;