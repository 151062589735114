import React, { useState } from 'react';

const RewardContext = React.createContext();

const RewardContextProvider = ({children}) => {
  const [ contextState, setContextState ] = useState({});
  
  return (
    <RewardContext.Provider value={ { contextState, setContextState } }>
      {children}
    </RewardContext.Provider>
  );
}

export { RewardContext, RewardContextProvider };