import React, { useState } from 'react';

const DataContext = React.createContext();

const DataContextProvider = ({children}) => {
  const [ contextState, setContextState ] = useState({ });
  
  return (
    <DataContext.Provider value={ { contextState, setContextState } }>
      {children}
    </DataContext.Provider>
  );
}

export { DataContext, DataContextProvider };