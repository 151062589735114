import React, { useState } from 'react';

const UserContext = React.createContext();

const UserContextProvider = ({children}) => {
  const [ contextState, setContextState ] = useState({ favorites: [], isSub: false });
  
  return (
    <UserContext.Provider value={ { contextState, setContextState } }>
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserContextProvider };