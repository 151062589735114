import React, { useState, useEffect, useContext } from 'react';
import Card from '../../components/card';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import CircularProgress from '@material-ui/core/CircularProgress';

import { DataContext } from '../../context/data';
import { NavContext } from '../../context/nav';
import { UserContext } from '../../context/user';
import track from '../../hooks';

window.Twitch.ext.onContext(function(context) {
  window.Twitch.ext.rig.log(context);
});

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  button: {
    textAlign: 'center',
    padding: '20px',
  },
});

function App() {
  const [ data, setData ] = useState();
  const [ dataAux, setDataAux ] = useState();
  const [ isUseFilter, setUseFilter ] = useState(false);
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const userContext = useContext(UserContext);
  const navContext = useContext(NavContext);

  const key = Cookies.get('key');

  useEffect(() => {
    loadData();
    loadUser();
  }, [navContext.contextState.showHome, userContext.contextState.id]);

  useEffect(() => {
    loadUser();
    setData();
  }, [navContext.contextState.showFavorites])

  window.Twitch.ext.onAuthorized(function() {
    if (window.Twitch.ext.viewer.id && userContext.contextState.id !== window.Twitch.ext.viewer.id) {
      saveUser(window.Twitch.ext.viewer.id);
      userContext.setContextState({
        ...userContext.contextState,
        id: window.Twitch.ext.viewer.id,
      })
    }
  });

  function saveUser(id) {
    fetch('https://www.chimotv.com.ar/api/user', {
        method: 'POST',
        body: JSON.stringify({
          userId: id,
          key: Cookies.get('key'),
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
  }

  function getUserParams() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('userId') && userContext.contextState.id !== params.get('userId')) {
      return params.get('userId');
    } else {
      return userContext.contextState.id;
    }
  }

  function isTwitchPanelExt() {
    return window.location.search.indexOf('anchor=panel') !== -1;
  }

  function loadData() {
    if (userContext.contextState.id || key) {
      fetch('https://www.chimotv.com.ar/api/data').then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
        dataContext.setContextState({
          allData: data,
        });
      });
    }
  }

  function loadUser() {
    let user = {};

    user.id = getUserParams();

    const userFetch = fetch(`https://www.chimotv.com.ar/api/user?userId=${user.id}`).then((response) => {
      return response.json();
    })
    .then((data) => {
      user.isSub = data.isSub;
      user.tier = data.tier;
      user.sub = data.sub;
      user.isTwitchSub = data.isTwitchSub;
    });

    const bookmarksFetch = fetch(`https://www.chimotv.com.ar/api/bookmarks?userId=${user.id}`).then((response) => {
      return response.json();
    })
    .then((data) => {
      user.bookmarks = data.bookmarks;
    });

    Promise.all([userFetch, bookmarksFetch]).then(() => {
      userContext.setContextState({
        isSub: user.isSub,
        tier: user.tier,
        favorites: user.bookmarks,
        id: user.id,
        sub: user.sub,
        isTwitchSub: user.isTwitchSub,
      });
    });
  }

  function onSelect(id) {
    data.forEach((game) => {
      if (game.id === id) {
        setData(game.rewards);
        window.scrollTo(0,0);
      };
    });
  }

  return (
    <>
    {
      (userContext.contextState.id || key) ?
        <Grid className={classes.root} container>
          {
              data ? data.map((game, i) => <Card onClick={onSelect} {...game} key={i} />) 
                    : <div style={{ padding: '15px', paddingLeft: '30px', alignSelf: 'center' }}>
                    <CircularProgress style={{
                                      width: '30px',
                                      height: '30px',
                                    }} color="primary" />
                  </div>
          }
        </Grid> :
        (isTwitchPanelExt()) ?
        <div className={classes.button} >
          <h5>Para poder usar la extensión se necesitan permisos, para poder activarlos hace lo siguiente:</h5>
          <img src="https://www.chimotv.com.ar/images/login-tw.png" style={ { width: "80%" } }/>
        </div> :
        <div className={classes.button} >
        <Button href="https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=9zkd34ly0j84k4spli223dvp5yv6yi&redirect_uri=https://www.chimotv.com.ar/connect&scope=user_read" 
                variant="contained" color="primary">
            Login con Twitch
        </Button>
      </div>  
    }
    </>
  );
}

// https://api.twitch.tv/kraken/oauth2/authorize?response_type=code&client_id=9zkd34ly0j84k4spli223dvp5yv6yi&redirect_uri=http://www.localhost.com/connect&scope=channel:read:redemptions+channel_subscriptions+channel_read+whispers:edit+chat:read+chat:edit+bits:read

export default App;