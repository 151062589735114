import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  IconButton,
  SvgIcon,
  Modal,
  Card,
  CardContent,
  Button,
  Typography,
  CardActions,
} from '@material-ui/core';
import Cookies from 'js-cookie';
import { NavContext } from '../context/nav';
import { UserContext } from '../context/user';
import { RewardContext } from '../context/reward';
import track from '../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    width: 400,
  },
  navbar: {
    paddingLeft: '5px',
    paddingRight: '5px',
    textAlign: 'center',
    backgroundColor: '#282a3a',
    height: '55px',
    borderBottomStyle: 'groove',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  iconButtonHome: {
    padding: '15px',
    margin: 'auto',
  },
  svgHome: {
    fill: 'white',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  modalRoot: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  modalInternal: {
    margin: 'auto',
    filter: 'drop-shadow(2px 4px 6px black)',
  }
}));

export default function Navbar() {
  const classes = useStyles();
  const navContext = useContext(NavContext);
  const userContext = useContext(UserContext);
  const rewardContext = useContext(RewardContext);
  const [ userData, setUserData ] = useState();
  const [ isLoadingPayment, setLoadingPayment ] = useState(false);
  const [ showPayment, setShowPayment ] = useState(false);
  const [open, setOpen] = useState(false);
  const key = Cookies.get('key');

  function onShowFavorites() {
    track('send', 'event', 'favorites', 'click', key);
    rewardContext.setContextState({});
    navContext.setContextState({
      showBuy: false,
      showFavorites: true,
      showHome: false,
      showUser: false,
    });
  }

  function onShowBuy() {
    navContext.setContextState({
      showBuy: true,
      showFavorites: false,
      showHome: false,
      showUser: false,
    });
  }

  function onShowHome() {
    track('send', 'event', 'home', 'click', key);
    // if (user) {
    //   window.Twitch.ext.rig.log(`${window.location.href}&userId=${user}`);
    //   window.location = `${window.location.href}&userId=${user}`;

    //  return; 
    // }

    navContext.setContextState({
      showBuy: false,
      showFavorites: false,
      showHome: !navContext.contextState.showHome,
      showUser: false,
    });
  }

  function onShowUser() {
    navContext.setContextState({
      showBuy: false,
      showFavorites: false,
      showHome: false,
      showUser: true,
    });
  }

  function onUser() {
    track('send', 'event', 'user', 'detail');
    fetch(`https://www.chimotv.com.ar/api/user?userId=${userContext.contextState.id}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUserData(data);
        setOpen(true);
      });
  }

  const handleClose = () => {
    setUserData(null);
    setShowPayment(false);
    setOpen(false);
  };

  function logout() {
    track('send', 'event', 'user', 'logout');
    Cookies.remove('key');
    window.location = '/';
  }

  return (
    <>
      <div className={classes.navbar}>
        <IconButton type="button" className={classes.iconButtonHome} onClick={ onShowHome } title="Home" aria-label="home">
          <SvgIcon className={classes.svgHome}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          </SvgIcon>
        </IconButton>
        <IconButton type="button" className={classes.iconButtonHome} title="Favoritos" onClick={ onShowFavorites } aria-label="favoritos">
          <SvgIcon className={classes.svgHome}>
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"></path>
          </SvgIcon>
        </IconButton>
        {
          (key || userContext.contextState.id) && (
            <>
              <IconButton type="button" className={classes.iconButtonHome} title="Compras" onClick={ onShowBuy } aria-label="comprar chimo coins">
                <SvgIcon className={classes.svgHome}>
                <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path>
                </SvgIcon>
              </IconButton>
              <IconButton type="button" className={classes.iconButtonHome} onClick={ onUser } title="Usuario" aria-label="usuarios">
                <SvgIcon className={classes.svgHome}>
                  <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                </SvgIcon>
              </IconButton>
            </>
          )
        }
         <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {
            userData &&
            <div className={classes.modalRoot} onClick={handleClose}>
              <div className={classes.modalInternal}>
              <Card style={ { height: '100%', display: 'grid', } }>
                  <CardContent>
                    <div>
                      <Typography variant="span">{`Usuario:  ${userData.name}`}</Typography>
                    </div>
                    <div>
                      <Typography variant="span">{`Puntos:  ${userData.points}`}</Typography>
                    </div>
                    <div style={{paddingTop: '10px'}}>
                    {
                      userData.isSub && <Typography variant="span">Suscripción</Typography>
                    }
                    </div>
                    <div>
                    { 
                      userData.isSub && <Typography variant="span">{`Nivel: ${userData.tier}`}</Typography>
                    }
                    </div>
                    <div>
                    {
                      userData.isSub && !userData.isTwitchSub && <Typography variant="span">{`Vence: ${userData.sub}`}</Typography>
                    }
                    </div>
                  </CardContent>
                  <CardActions style={{flexFlow: 'column-reverse'}}>
                    <Button variant="contained" color="primary" onClick={logout}>
                      Logout
                    </Button>
                  </CardActions>
              </Card>
              </div>
            </div>
          }
        </Modal>

      </div>
    </>
  )
}
