import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import { Grid, IconButton, SvgIcon } from '@material-ui/core';
import Cookies from 'js-cookie';
import { NavContext } from '../context/nav';
import { UserContext } from '../context/user';
import { RewardContext } from '../context/reward';
import track from '../hooks';

const useStyles = makeStyles({
  media: {
    marginTop: '15px',
    height: '100px',
    width: '100px',
    backgroundSize: 'cover',
    borderRadius: '70px',
    margin: 'auto',
  },
  snackbarRoot: {
    bottom: '35px',
  },
  snackbar: {
    backgroundColor: '#4caf50',
    filter: 'drop-shadow(2px 4px 6px black)',
    width: '250px',
    display: 'table',
    textAlign: 'center',
    height: '35px',
    lineHeight: '1.43',
    borderRadius: '4px',
    letterSpacing: '0.01071em',
  },
  success: {
    backgroundColor: '#4caf50',
  },
  error: {
    backgroundColor: '#f44336',
  },
  message: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#fff',
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  button: {
    width: '100%',
  },
  cardContent: {
    textAlign: 'center',
    "&:last-child": {
      paddingBottom: '5px'
    }
  }, 
  points: {
    fontSize: '18px',
    marginTop: '10px',
  },
  pointsSub: {
    fontSize: '12px',
    marginTop: '10px',
    color: '#00000054',
    textDecoration: 'line-through',
  },
  sub: {
    fontSize: '18px',
    marginTop: '0px',
    color: '#5bb53f',
  },
  grid: {
    padding: '10px',
  },
  iconHeart: {
    borderRadius: '0',
    padding: '20px',
    float: 'right',
    '&:hover': {
      backgroundColor: 'white',
   },
  },
  iconHelp: {
    borderRadius: '0',
    padding: '20px',
    float: 'left',
    '&:hover': {
      backgroundColor: 'white',
   },
  },
  svgHeart: {
    color: '#6b6b6bd9',
  },
  svgHelp: {
    color: '#000000b5',
  },
  svgHeartActive: {
    color: '#dc3b3bd9',
  }
});


export default function CardReward(props) {
  const [ message, setMessage ] = useState();
  const [ favorite, setFavorite ] = useState();
  const [ isShowInfo, setIsShowInfo ] = useState();
  const [ isDisabled, setDisabled ] = useState();
  const navContext = useContext(NavContext);
  const userContext = useContext(UserContext);
  const rewardContext = useContext(RewardContext);
  const classes = useStyles();
  const { onClick, id, isItem } = props;

  function itemClick() {
    rewardContext.setContextState({
      selected: id,
    });
    onClick(id);
  }

  useEffect(() => {
    setFavorite(false);
    if (isItem && userContext.contextState.favorites && userContext.contextState.favorites.some((favorite) => `${window.idSelected}-${id}` === favorite.id)) {
      setFavorite(true);
    }
  }, [isItem, userContext.contextState.favorites])

  function submit() {
    track('send', 'event', 'reward', 'click', `${rewardContext.contextState.selected}-${id}`);
    setDisabled(true);
    fetch('https://www.chimotv.com.ar/api/send', {
        method: 'POST',
        body: JSON.stringify({
          userId: userContext.contextState.id,
          itemId: rewardContext.contextState.selected != undefined ? `${rewardContext.contextState.selected}-${id}` : id,
          key: Cookies.get('key'),
        }),
        headers:{
            'Content-Type': 'application/json',
        },
    }).then((response) => {
      return response.json();
    }).then((data) => {
      setDisabled(false);
      setMessage({ ...data, show: true, });
    });
  }

  function onHelp() {
    track('send', 'event', 'info', 'click', `${window.idSelected}-${id}`);
    setIsShowInfo(!isShowInfo);
  }

  function onFavorite() {
    track('send', 'event', 'favorite', 'click', `${window.idSelected}-${id}`);
    fetch('https://www.chimotv.com.ar/api/bookmarks', {
        method: 'POST',
        body: JSON.stringify({
          userId: userContext.contextState.id,
          itemId: `${window.idSelected}-${id}`,
        }),
        headers:{
            'Content-Type': 'application/json',
        },
    }).then((response) => {
      if (response.status === 200) {
        setFavorite(!favorite);
      }
    });
  }

  function getCardMedia(classes, isSub, {title, description, image, isItem, points, noSub, isPromo}) {
    if (noSub) {
      isSub = false;
    }

    let promoPoints = 0;

    if (isPromo) {
      promoPoints = points / 2;
    }

    if (isSub) {
      promoPoints = points - points * userContext.contextState.tier * 25 / 100;
    }

    promoPoints = Math.round(promoPoints);

    return (
      <div style={ {textAlign: '-webkit-center'} }>
        { 
          isItem && !navContext.contextState.showFavorites &&
          <IconButton type="button" className={classes.iconHelp} onClick={ onHelp } aria-label="home">
            <SvgIcon className={ classes.svgHelp }>
                <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
            </SvgIcon>
          </IconButton>
        }
        { 
          isItem && !navContext.contextState.showFavorites &&
          <IconButton type="button" className={classes.iconHeart} onClick={ onFavorite } aria-label="home">
            <SvgIcon className={ favorite ? classes.svgHeartActive : classes.svgHeart }>
              {
                favorite ?
                <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"></path> :
                <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"></path>  
              }
            </SvgIcon>
          </IconButton>
        }
        {
          image && 
          <CardMedia
            className={classes.media}
            image={`https://www.chimotv.com.ar${image}`}
            title={title}
          />
        }
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          {
            description &&
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          }
          {
            isItem && (
              <>
                <Typography variant="body2" color="primary" component="h2" className={ isSub || isPromo ? classes.pointsSub : classes.points }>
                  {points} Chimo Coins
                </Typography>
                {
                  (isSub || isPromo) && 
                    <Typography variant="body2" color="primary" component="h2" className={ classes.sub }>
                    {promoPoints} Chimo Coins
                    </Typography>
                }
              </>
            )
          }
          {
            isShowInfo && 
            <>
              <Typography variant="body2" color="textSecondary" component="p" style={{
                fontSize: '10px',
                marginTop: '7px',
              }}>
                {Math.round(points / 25)} Bits = {points} Chimo Coins
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p" style={{
                fontSize: '10px',
                marginTop: '7px',
              }}>
                ${Math.round(points * 0.13)} ARS = {points} Chimo Coins
              </Typography>
            </>
          }
          { props.children }
        </CardContent>
      </div>);
  }

  const cardMedia = getCardMedia(classes, userContext.contextState.isSub, props);

  return (
    <>
      {
        message &&
        <Snackbar className={classes.snackbarRoot} open={message.show} autoHideDuration={6000} onClose={ () => setMessage() }> 
          <div className={ message.success ?
              `${classes.snackbar} ${classes.success}` :
              `${classes.snackbar} ${classes.error}` }>
          <Typography className={classes.message} variant="h5" component="h2">
            Tenes { message.points } puntos!
          </Typography>
          </div>
        </Snackbar>
      }
      <Grid className={ classes.grid } item xs={12} sm={6} md={3}>
        <Card style={ { height: '100%', display: 'grid', } }>
          {
            isItem ?
            <>
              { cardMedia }
            </> :
            <CardActionArea onClick={itemClick}>
              { cardMedia }
            </CardActionArea>
          }
          {
            isItem &&
            <CardActions style={{flexFlow: 'column-reverse'}}>
              <Button disabled={isDisabled} onClick={submit} className={classes.button} variant="contained" color="primary">
                {props.textButton || 'Canjear'}
              </Button>
            </CardActions>
          }
        </Card>
      </Grid>
    </>
  );
}
