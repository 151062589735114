import React, { useContext, useEffect } from 'react';
import { DataContext } from '../../context/data';
import { UserContext } from '../../context/user';
import Card from '../../components/card';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

export default function Favorites(props) {
  const dataContext = useContext(DataContext);
  const userContext = useContext(UserContext);
  const classes = useStyles();

  useEffect(() => {
    loadBookmarks();
  }, []);

  function loadBookmarks() {
    fetch(`https://www.chimotv.com.ar/api/bookmarks?userId=${userContext.contextState.id}`).then((response) => {
      return response.json();
    })
    .then((data) => {
      userContext.setContextState({
        ...userContext.contextState,
        favorites: data.bookmarks,
      });
    });
  }

  function getFavoritesData() {
    const selectFavorites = [];

    userContext.contextState.favorites.forEach((favorite) => {
      const splitFavorite = favorite.id.split('-');

      dataContext.contextState.allData.forEach((game) => {
        if (game.id.toString() === splitFavorite[0]) {
          game.rewards.forEach((reward) => {
            if (reward.id.toString() === splitFavorite[1]) {
              const newReward = {
                ...reward,
                id: favorite.id,
              };

              selectFavorites.push(newReward);
            }
          });
        }
      });
    });

    return selectFavorites;
  }

  return (
    <Grid className={classes.root} container>
      {
        getFavoritesData().map((game, i) => <Card {...game} key={i} />)
      }
    </Grid>
  );
}
