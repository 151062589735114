import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button, Card, CardContent, Typography, CardActions, TextField, CircularProgress } from '@material-ui/core';
import { UserContext } from '../../context/user';
import track from '../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import SubComponente from '../../components/sub';

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  button: {
    width: '100%',
  },
  grid: {
    padding: '10px',
  },
  cardContent: {
    textAlign: 'center',
    "&:last-child": {
      paddingBottom: '5px'
    }
  },
  inputChimo: {
    width: '190px',
    textAlign: 'center',
  },
  inputMsj: {
    width: '250px',
  },
  gridInputs: {
    marginTop: '15px'
  },
});

const subs = [{
    id: 1,
    title: 'Suscripción Nivel 1',
    info: ['1000 Chimo Coins', '25% de descuento en Rewards', 'Multiplicador del 1.2'],
    price: 500,
  },{
    id: 2,
    title: 'Suscripción Nivel 2',
    info: ['2000 Chimo Coins', '50% de descuento en Rewards', 'Multiplicador del 1.4'],
    price: 1000,
  },{
    id: 3,
    title: 'Suscripción Nivel 3',
    info: ['3000 Chimo Coins', '75% de descuento en Rewards', 'Multiplicador del 2.0'],
    price: 1600,
  }
];

const Buy = () => {
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [ chimoCoins, setChimoCoins ] = useState(1000);
  const [ msj, setMsj ] = useState();
  const [ isLoadingPayment, setLoadingPayment ] = useState();

  function onChangeChimo(evt) {
    if (!/^[0-9]*$/.test(evt.currentTarget.value)) {
      return;
    }

    setChimoCoins(evt.currentTarget.value);
  }

  function onChangeMsj(evt) {
    setMsj(evt.currentTarget.value);
  }

  function openLink(url) {
    let link = window.parent.document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.click();
    link = null;
  }

  function onChimoCoins() {
    if (chimoCoins < 1000) {
      return;
    }

    setLoadingPayment(true);
    fetch('https://www.chimotv.com.ar/api/buy', {
      method: 'POST',
      body: JSON.stringify({
        userId: userContext.contextState.id,
        type: 'chimocoins',
        msg: msj,
        quantity: chimoCoins,
      }),
      headers: {
          'Content-Type': 'application/json',
      },
    }).then((response) => {
      return response.text();
    })
    .then((data) => {
      openLink(data);
      setLoadingPayment(false);
    });
  }

  return (
    <Grid className={classes.root} container>
      {
        subs.map((sub, i) => <SubComponente key={i} {...sub} /> )
      }
      <Grid className={ classes.grid } item xs={12} sm={6} md={3}>
        <Card style={ { height: '100%', display: 'grid', } }>
          <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                Comprar Chimo Coins
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Ingresá la cantidad que quieras comprar, el mínimo son 1000 Chimo Coins.
              </Typography>
              <Grid className={classes.gridInputs}>
                <TextField className={classes.inputChimo} value={chimoCoins} onChange={onChangeChimo} id="chimo-coins" label="Cantidad de Chimo Coins" />
              </Grid>
              {/* <Grid className={classes.gridInputs}>
                <TextField className={classes.inputMsj} onChange={onChangeMsj} id="text-alert" label="Mensaje alerta" />
              </Grid> */}
          </CardContent>
          <CardActions style={{flexFlow: 'column-reverse'}}>
          {
              isLoadingPayment ? 
              <div style={{ padding: '15px', paddingLeft: '30px', alignSelf: 'center' }}>
                <CircularProgress style={{
                                  width: '20px',
                                  height: '20px',
                                }} color="primary" />
              </div> :
              <Button onClick={onChimoCoins} className={classes.button} variant="contained" color="primary">
                { chimoCoins ? `Comprar por $${(chimoCoins * 0.13).toFixed(2)}` : 'Comprar' }
              </Button>
            } 
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Buy;