import React, { useState } from 'react';

const NavContext = React.createContext();

const NavContextProvider = ({children}) => {
  const [ contextState, setContextState ] = useState({ });
  
  return (
    <NavContext.Provider value={ { contextState, setContextState } }>
      {children}
    </NavContext.Provider>
  );
}

export { NavContext, NavContextProvider };