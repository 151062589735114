import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button, Card, CardContent, Typography, CardActions, TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../context/user';

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  button: {
    width: '100%',
  },
  grid: {
    padding: '10px',
  },
  cardContent: {
    textAlign: 'center',
    "&:last-child": {
      paddingBottom: '5px'
    }
  },
  inputChimo: {
    width: '190px',
    textAlign: 'center',
  },
  inputMsj: {
    width: '250px',
  },
  gridInputs: {
    marginTop: '15px'
  },
  list: {
    width:'200px',
    margin: 'auto',
    textAlign: 'initial',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

function Sub({title, info, price, id}) {
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [ msjSub, setMsjSub ] = useState();
  const [ isLoadingPayment, setLoadingPayment ] = useState();

  function openLink(url) {
    let link = window.parent.document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.click();
    link = null;
  }

  function onChangeMsjSub(evt) {
    setMsjSub(evt.currentTarget.value);
  }

  function onSub() {
    setLoadingPayment(true);
    fetch('https://www.chimotv.com.ar/api/buy', {
      method: 'POST',
      body: JSON.stringify({
        userId: userContext.contextState.id,
        type: 'sub',
        msg: msjSub,
        tier: id,
      }),
      headers: {
          'Content-Type': 'application/json',
      },
    }).then((response) => {
      return response.text();
    })
    .then((data) => {
      openLink(data);
      setLoadingPayment(false);
    });
  }

  return (
    <Grid className={ classes.grid } item xs={12} sm={6} md={3}>
        <Card style={ { height: '100%', display: 'grid', } }>
          <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
              <ul className={classes.list} color="primary">
                {
                  info.map((data) => <li>{data}</li>)
                }
              </ul>
              {/* <Grid className={classes.gridInputs}>
                <TextField className={classes.inputMsj} onChange={onChangeMsjSub} label="Mensaje alerta" />
              </Grid> */}
          </CardContent>
          <CardActions style={{flexFlow: 'column-reverse'}}>
            {
              isLoadingPayment ? 
              <div style={{ padding: '15px', paddingLeft: '30px', alignSelf: 'center' }}>
                <CircularProgress style={{
                                  width: '20px',
                                  height: '20px',
                                }} color="primary" />
              </div> :
              <Button disabled={userContext.contextState.isTwitchSub} className={classes.button} onClick={onSub} variant="contained" color="primary">
                Comprar por ${price}
              </Button>
            }            
          </CardActions>
        </Card>
      </Grid>
  )
}

export default Sub;