import React, { useContext } from 'react';
import Navbar from '../components/navbar';
import { NavContext } from '../context/nav';
import Favorites from './favorites';
import Home from './home';
// import Reward from './reward';
// import User from './user';
import Buy from './buy';

function Layout() {
  const context = useContext(NavContext);

  function getPage() {
    let view = <Home />;

    if (context.contextState.showFavorites) {
      view = <Favorites />
    }

    if (context.contextState.showHome) {
      view = <Home />
    }

    // if (context.contextState.showUser) {
    //   view = <User />
    // }

    if (context.contextState.showBuy) {
      view = <Buy />
    }

    return view;
  }

  return (
    <>
      <Navbar />
      {
        getPage()
      }
      <div style={{paddingTop: '30px'}}></div>
    </>
  );
}

export default Layout;