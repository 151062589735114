import React from 'react';
import ContextProvider from './context';
import Layout from './pages/layout';

function App() {
  return (
    <ContextProvider>
      <Layout />
    </ContextProvider>
  );
}

// https://api.twitch.tv/kraken/oauth2/authorize?response_type=code&client_id=9zkd34ly0j84k4spli223dvp5yv6yi&redirect_uri=http://www.localhost.com/connect&scope=channel:read:redemptions+channel_subscriptions+channel_read+whispers:edit+chat:read+chat:edit+bits:read

export default App;